<template>
    <div> {{hostClients.length}} </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: { type: Object, require: true }
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            clients: 'host/clients'
        }),
        hostClients(){
            return [...this.clients].filter(item => item.host === this.object.uid)
        }
    }
}
</script>